import React from 'react';
import Head from 'next/head';
import { withRouter } from 'next/router';
import Util from '../util';
import Constants from '../util/Constants';
import PageSection from './PageSection';
import Meta from '../util/Meta';

const DOMAIN = `https://${Constants.WEB_LINK.toLowerCase()}`;

function Page({
	children,
	showPromotions,
	className = '',
	meta = {},
	noindex = false,
	error,
	padding = true,
	...props
}) {
	const router = props.router;
	const metaTitle = Util.titleCase(meta.title || Meta.TITLE);
	const metaDescription = meta.description || Meta.DESCRIPTION;
	const metaImage = meta.image
		? meta.image.includes('http')
			? meta.image
			: `${DOMAIN}/${meta.image}`
		: `${DOMAIN}/${Meta.IMAGE}`;
	const metaSite =
		typeof window === 'undefined'
			? `${DOMAIN}${router ? router.asPath || router.pathname : ''}/`
			: `${window.location.href}/`;

	return (
		<div className={`page-root ${className}`}>
			<Head>
				<title>{metaTitle}</title>
				<meta property="og:title" content={metaTitle} />
				<meta name="twitter:title" content={metaTitle} />
				<meta name="description" content={metaDescription} />
				<meta property="og:description" content={metaDescription} />
				<meta name="twitter:description" content={metaDescription} />
				<meta property="image" content={metaImage} />
				<meta property="og:image" content={metaImage} />
				<meta name="twitter:image" content={metaImage} />
				<meta property="og:url" content={metaSite} />
				<meta name="twitter:url" content={metaSite} />
				{noindex ? (
					<meta name="robots" content="noindex" />
				) : (
					<meta
						name="robots"
						content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
					/>
				)}
			</Head>
			{padding ? <PageSection>{children}</PageSection> : children}
		</div>
	);
}

export default withRouter(Page);
