import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';

export default function Spinner({ size = 'small', style }) {
	return (
		<div className="spinner" style={style}>
			<LoadingOutlined style={{ fontSize: size === 'small' ? 24 : 36 }} spin />
		</div>
	);
}
