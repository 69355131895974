import Spinner from './Spinner';

export function HtmlButton({
	type = 'filled',
	color = 'primary',
	size = '',
	className = '',
	onClick,
	htmlType,
	disabled,
	transparent = false,
	loading,
	...props
}) {
	return (
		<button
			{...props}
			type={htmlType}
			className={`button ${type} color-${color} ${size ? `size-${size}` : ''} ${
				transparent ? 'transparent' : ''
			} ${className}`}
			onClick={(e) => {
				if (!htmlType || htmlType !== 'submit') {
					e.preventDefault();
					if (e.stopImmediatePropagation) e.stopImmediatePropagation();
				}

				onClick && onClick();
			}}
			disabled={loading || disabled}
		>
			{loading && <Spinner size="small" />}
			{props.children}
		</button>
	);
}
