const Constants = {
	WEB_LINK:
		process.env.NODE_ENV === 'production' ? 'swanvo.com' : 'localhost:3000',
	BRAND_NAME: 'Swanvo',

	DEBUG_AUTH: false,
	DEBUG: process.env.NODE_ENV !== 'production',

	/** SWR CONFIG */
	SWR_CONFIG: {
		revalidateOnFocus: false,
		revalidateOnMount: true,
		revalidateOnReconnect: true,
		refreshWhenOffline: false,
		refreshWhenHidden: false,
		refreshInterval: 0,
	},
};

export default Constants;
