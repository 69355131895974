import Constants from './Constants';

const DEFAULT_TITLE = Constants.BRAND_NAME;
const DEFAULT_DESCRIPTION = '';

const Meta = {
	TITLE: DEFAULT_TITLE,
	DESCRIPTION: DEFAULT_DESCRIPTION,
	IMAGE: 'images/bird-background.png',

	Title: (text) => `${text} | ${Constants.BRAND_NAME}`,
	UserAchievements: (user) => `Achievements | ${user.name || user.username}`,

	Landing: {
		title: `${Constants.BRAND_NAME} | Your Recognition Platform`,
	},
	PrivacyPolicy: {
		title: `Privacy Policy | ${Constants.BRAND_NAME}`,
		description: null,
	},
	Login: {
		title: `${Constants.BRAND_NAME} | Your Recognition Platform`,
	},
};

export default Meta;
